import './Sass/styles.scss';
import { BrowserRouter as Router, Link, Route, Routes, Navigate } from "react-router-dom";
import Logo from './images/banefilter-logo-farge.jpg';
import Home from './pages/Home';
import OmOss from './pages/OmOss'
import ProductPage from './pages/ProductPage';
import DetailsPage from './pages/DetailsPage';
import NewsPage from './pages/NewsPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';
import ContactUs from './pages/ContactUs';
import { useState } from 'react';
import Navlinks from './pages/components/Navlinks';
import { motion } from "framer-motion";
import Bestilling from './pages/Bestilling';
import Takk from './pages/Takk';
import TakkContact from './pages/TakkContact';



function App() {

  const [open, setOpen] = useState(false);

  const hamburgerIcon = <FontAwesomeIcon id='hamburger-label' icon={faBars} onClick={() => setOpen(!open)} />
  const closeIcon = <FontAwesomeIcon id='hamburger-label' icon={faX} onClick={() => setOpen(!open)} />
  const animateNavFrom = {opacity: 0, x: -50};
  const animateNavTo = {opacity: 1, x: 0};

  return (
    <Router>
      <nav>
        {open ? closeIcon : hamburgerIcon}
        <div className='nav-top'>
          <Link className='nav-logo' to="/">
            <img
              src={Logo}
              width="300"
              alt="Banefilter logo"
            />
          </Link>
        </div>
        <Navlinks />
        {open && 
          <div id='nav-mob'>
              <motion.ul initial={animateNavFrom} animate={animateNavTo}>
                  <motion.li initial={animateNavFrom} animate={animateNavTo} transition={{delay: 0.05}}><Link onClick={() => setOpen(!open)} className='nav-links' to="/">Hjem</Link></motion.li>
                  <motion.li initial={animateNavFrom} animate={animateNavTo} transition={{delay: 0.10}}><Link onClick={() => setOpen(!open)} className='nav-links' to="/produkter">Produkter</Link></motion.li>
                  <motion.li initial={animateNavFrom} animate={animateNavTo} transition={{delay: 0.15}}><Link onClick={() => setOpen(!open)} className='nav-links' to="/kontakt">Kontakt</Link></motion.li>
                  <motion.li initial={animateNavFrom} animate={animateNavTo} transition={{delay: 0.20}}><Link onClick={() => setOpen(!open)} className='nav-links' to="/om-oss">Om oss</Link></motion.li>
                  <motion.li initial={animateNavFrom} animate={animateNavTo} transition={{delay: 0.25}}><Link onClick={() => setOpen(!open)} className='nav-links' to="/nyheter">Nyheter</Link></motion.li>
                  <motion.li initial={animateNavFrom} animate={animateNavTo} transition={{delay: 0.30}}><Link onClick={() => setOpen(!open)} className='nav-links' to="/bestilling">Bestilling</Link></motion.li>
              </motion.ul>
              <div className='overlay-website'></div>
          </div>}       
      </nav>
      <div>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/om-oss" exact element={<OmOss />} />
          <Route path="/produkter" exact element={<ProductPage />} />
          <Route path="/detail/:id" element={<DetailsPage />} />
          <Route path="/nyheter" element={<NewsPage />} />
          <Route path="/kontakt" element={<ContactUs />} />
          <Route path="/bestilling" element={<Bestilling />} />
          <Route path="/takk" element={<Takk />} />
          <Route path="/takk-kontakt" element={<TakkContact />} />

        <Route path='/publikumssluse' element={ <Navigate to="/detail/3166" /> }/>
        <Route path='/granulatfilter-monterinsgring60' element={ <Navigate to="/detail/3173" /> }/>
        <Route path='/granulatfilter' element={ <Navigate to="/detail/3173" /> }/>
        <Route path='/spillerstasjon' element={ <Navigate to="/detail/3157" /> }/>
        <Route path='/ministasjon' element={ <Navigate to="/detail/3183" /> }/>
        <Route path='/granulatfilter-monterinsgring35' element={ <Navigate to="/detail/3189" /> }/>
        <Route path='/category/produkter' element={ <Navigate to="/produkter" /> }/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
