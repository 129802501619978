import React from 'react';
import { productsUrl } from '../api/Api';
import { useState, useEffect } from "react";
import OrderProducts from "./components/OrderProducts";
import emailjs from 'emailjs-com';
import Footer from './components/Footer';
import { useNavigate } from 'react-router-dom';



function Bestilling() {

  const [product, setProduct] = useState([]);
  const history = useNavigate(); 

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('send.one.com', 'template_g5yb18k', e.target, 'j-vjB7N1w31QVisAg')
      .then((result) => {
        if (result.text === 'OK') {
          history('/takk');
        }
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
    window.scrollTo(0, 0);
  };

   
  
  useEffect( function() {
        async function fetchProduct() {
            try{
                const resp = await fetch(productsUrl);

                if (resp.ok) {
                  const data = await resp.json();
                  setProduct(data);
                  
                }
            
            } catch (error) {
                console.log(error);
            }

        }fetchProduct();
        // eslint-disable-next-line
  }, [productsUrl]);

    return (
      <div>
        <div className='order-heading'>
          <h1>Bestilling</h1>
        </div>

        <div className='form-section'>

          <div className='form-block'>
            <form onSubmit={sendEmail}>
              <div className="form-container ">
                <div className="fields firma">
                  <div className='field'>
                    <label>Frima/ Navn <span>*</span></label>
                    <input type="text" className="form-control" placeholder="Firma" name="firma" reduired/>
                  </div>

                  <div className='field'>
                    <label>Organisasjonsnummer</label>
                    <input type="text" className="form-control" placeholder="Organisasjonsnummer" name="org" />
                  </div>
                </div>


                <div className="fields kontakt-person">
                  <label>Kontakt person <span>*</span></label>
                  <div className='field'>
                    <input type="text" className="form-control" placeholder="Fornavn" name="first" required/>
                    <input type="text" className="form-control" placeholder="Etternavn" name="last" required/>
                  </div>
                </div>

                <div className="fields">
                  <label>Email <span>*</span></label>
                  <input type="email" className="form-control" placeholder="Email" name="email" required/>
                </div>
                
                <div className="fields">
                  <label>Mobil <span>*</span></label>
                  <input type="text" className="form-control" placeholder="Mobilnummer" name="mobil" required/>
                </div>

                <div className="fields">
                  <label>Leverings adresse <span>*</span></label>
                  <input type="text" className="form-control" placeholder="Leverings adresse" name="leverings" required/>
                </div>

                <div className="fields">
                  <label>Faktura adresse <span>*</span></label>
                  <input type="text" className="form-control" placeholder="Faktura adresse" name="faktura" required/>
                </div>

                <div className="fields">
                  <label>Andre spørsmål/avtaler</label>
                  <textarea className="form-control" id="" cols="30" rows="8" placeholder="Melding/Henvendelse" name="message"></textarea>
                </div>

              </div>
              
              <div className='order-products'>
                <h2>Hak ut produktet og velg antall:</h2>
                <div className='order-products-grid'>
                  {product.map( function (data) {
                    const id = data.id;
                    const title = data.acf.navn;
                    const pris = data.acf.pris;
                    const img1 = data.acf.img1;

                    return <OrderProducts key={id} id={id} pris={pris} title={title} img1={img1} />
                  })}
                </div>
              </div>
              <div className='button-form'>
                <input type="submit" className="btn" value="Send bestilling"></input>
              </div>
              
            </form>
          </div>
        </div>

        <Footer />
      </div>
    )

  
}

export default Bestilling;