import React from 'react'
import { Link } from 'react-router-dom'
import Footer from './components/Footer'

function TakkContact() {


  return (
    <div>
        <div className='takk-heading'>
            <h1>Takk for henvendelsen</h1>
        </div>
        <div className='takk-body'>
            <h2>Takk for din Henvendelse! Vi vil ta kontakt så fort som mulig.</h2>
            <Link className='takk-knapp' to="/">
                Hjem
            </Link>
        </div>

        <Footer />
    </div>
  )
}

export default TakkContact