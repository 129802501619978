import React from 'react';
import { useState, useEffect } from "react";
import { homeProducts } from '../../api/Api';
import GetProducts from './Getproducts';
import Loader1 from '../../images/banefilter-loader1.svg'



function PopularProducts() { 
  
    const [product,setProduct] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect( () => {
        async function fetchHomeproduct() {
            try{
                const resp = await fetch(homeProducts);

                if(resp.ok) {
                    const json = await resp.json();
                    setProduct(json);
                } else {
                    setError("Something in the API might be wrong!");
                }
            
            } catch (error) {
                setError(error.toString());
            } finally{
                setLoading(false);
            }
        } fetchHomeproduct();
    }, []);

    if (loading) {
        return (
            <div id='loader-div'>
                <img id='loader' src={Loader1} alt="laoder" />
            </div>
            
        )
    }


    if (error) {
        return <div>ERROR: {error}</div>
    }
  
    return (
        <div className='product-sec-main-products'>
            {product.map( function (data){
                const id = data.id;
                const title = data.acf.navn;
                const pris = data.acf.pris;
                const img1 = data.acf.img1;
                const sumpris = data.acf.sumpris;
                // const img2 = data.acf.img2;
                // const img3 = data.acf.img3;
                // const img4 = data.acf.img4;
                // const img5 = data.acf.img5;
                const undertext = data.acf.undertekst;
                const pb1 = data.acf.pb1;
                // const pb2 = data.acf.pb2;

                return <GetProducts key={id} pb1={pb1} sumpris={sumpris} undertext={undertext} id={id} img={img1} title={title} price={pris} />;
            })}  
        </div>
    )
}

export default PopularProducts;