import React from 'react';
import { Link } from 'react-router-dom';

function GetPopularNews({ image, bg, heading }) {
  return (
    <Link to="/nyheter" className='popular-news-block' style={{backgroundImage: `url(${bg})`}}>
        <div className='left'>
            <img src={image}  alt={heading} />
        </div>
        <div className='right'>
            <h2>{heading}</h2>
            <button>Se mer</button>
        </div>
    </Link>
  )
}

export default GetPopularNews;