import React, { useEffect } from 'react';
import { InputGroup, DropdownButton } from 'react-bootstrap';

function ProductFilter({ setActiveCategory, activeCategory, setCatFiltered, product}) {
  
  useEffect(() => {
    if (activeCategory === 27) {
      setCatFiltered(product);
      return;
    }
    
    const filtered = product.filter((prod) => 
      prod.categories.includes(activeCategory)
    );

    setCatFiltered(filtered);
    // eslint-disable-next-line
  }, [activeCategory]);
  
  return (
    <>
        <div className='product-page-filter'>
            <div className='product-page-filter-content'>
                <h4>Kategorier:</h4>
                <button className={activeCategory === 27 ? "active" : ""} onClick={() => setActiveCategory(27)}>Alle produkter</button>
                <button className={activeCategory === 30 ? "active" : ""} onClick={() => setActiveCategory(30)}>Granulatfilter</button>
                <button className={activeCategory === 28 ? "active" : ""} onClick={() => setActiveCategory(28)}>Ristmoduler</button>
                <button className={activeCategory === 31 ? "active" : ""} onClick={() => setActiveCategory(31)}>Skilt</button>
                <button className={activeCategory === 29 ? "active" : ""} onClick={() => setActiveCategory(29)}>Vant / Fysisk barriere</button>
            </div>
        </div>
        <div className='filter-mobile'>
            <InputGroup className="mb-3">
                <DropdownButton
                variant="outline-secondary"
                title="Handle etter"
                id="input-group-dropdown-1"
                >
                    <button className={activeCategory === 27 ? "active" : ""} onClick={() => setActiveCategory(27)}>Alle produkter</button>
                    <button className={activeCategory === 30 ? "active" : ""} onClick={() => setActiveCategory(30)}>Granulatfilter</button>
                    <button className={activeCategory === 28 ? "active" : ""} onClick={() => setActiveCategory(28)}>Ristmoduler</button>
                    <button className={activeCategory === 31 ? "active" : ""} onClick={() => setActiveCategory(31)}>Skilt</button>
                    <button className={activeCategory === 29 ? "active" : ""} onClick={() => setActiveCategory(29)}>Vant / Fysisk barriere</button>
                </DropdownButton>
            </InputGroup>
        </div>
    </>
  )
}

export default ProductFilter;