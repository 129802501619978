import React from 'react';
import Footer from './components/Footer';
import ProductMain from './productPage/ProductMain';

function ProductPage() {
  return (
    <div>
        <div className='product-page-heading'>
            <h1>Produkter</h1>
        </div>
        <ProductMain />
        <Footer />
    </div>
  )
}

export default ProductPage;