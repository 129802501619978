import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import heroLogo from '../images/banefilter-logo-hvit.png';
import granulatFilter from '../images/granulatfilter.png';
import PopularProducts from './components/PopularProducts';
import PopularNews from './components/PopularNews';
import Footer from './components/Footer';


function Home() {

    const articleStructuredData = {
        "@context": "http://schema.org",
        "@type": "Article",
        headline: "Granulatfilter",
        name: "Granulatfilter",
        image: `${granulatFilter}`,
        articleBody: "Ved å montere granulatfilter i alle avløpskummer rundt kunstgressbaner, er det med på å hindre at gummigranulaten havner i avløpet for å så spre seg ut i naturen. Granulat som blir samlet opp kan gjenbrukes på banen. Hvis en innfører rutiner på tømming av filterne har det et filter som varer i mange år.",
    }

    const [open, setOpen] = useState(false);
        
  return (
    <>
        <main>
            <script type="application/ld+json">
                {JSON.stringify(articleStructuredData)}
            </script>
            <div className='hero'>
                <div className='hero-content'>
                    <img src={heroLogo} alt="banefilter logo" />
                    <Link to="/produkter"><button className='cta-btn'>Produkter</button></Link>
                </div>
            </div>
            <div className='product-sec-main'>
                <div className='product-sec-main-heading'>
                    <h2>VÅRE MEST SOLGTE PRODUKTER</h2>
                </div>
                <PopularProducts />
            </div>
            <div className='news-section'>
                <h2>NYHETER</h2>
                <PopularNews />
            </div>
            <div className='granulatfilter-section'>
                <div className='granulatfilter-section-content'>
                    <div className='gr-filter-left'>
                        <h1>{articleStructuredData.headline}</h1>
                        <p>{articleStructuredData.articleBody}</p>
                        <Link to="/produkter"><button className='cta-btn'>Produkter</button></Link>
                    </div>
                    <div className='gr-filter-right'>
                        <img src={articleStructuredData.image} alt='granulat filter' onClick={() => {setOpen(!open)}} />
                        {open && 
                            <div id="myModal" className="modal">
                                <span onClick={() => {setOpen(!open)}} className="close">&times;</span>
                                <img className='modal-content' src={articleStructuredData.image} alt={articleStructuredData.headline} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </>
  )
}

export default Home