import React from 'react';
import { useState, useEffect } from "react";
import { news } from '../api/Api';
import GetNews from './components/GetNews';
import Loader from '../images/banefilter-loader.svg'
import Footer from './components/Footer';

function NewsPage() { 
  
    const [newsInfo, setNewsInfo] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect( () => {
        async function fetchNews() {
            try{
                const resp = await fetch(news);

                if(resp.ok) {
                    const json = await resp.json();
                    setNewsInfo(json);
                } else {
                    setError("Something in the API might be wrong!");
                }
            
            } catch (error) {
                setError(error.toString());
            } finally{
                setLoading(false);
            }
        }fetchNews();

    }, []);

    if (error) {
        return <div>ERROR: {error}</div>
    }

    if (loading) {
        return (
            <div className='news-page'>
                <h1>Nyheter</h1>
                <div id='loader-div'>
                    <img id='loader' src={Loader} alt="laoder" />
                </div>
            </div>
            
        )
    }
  
    return (
        <>
            <div className='news-page'>
                <h1>Nyheter</h1>
                <div className='news-section'>
                    {newsInfo.map( function (data){
                        const id = data.id;
                        const heading = data.acf.heading;
                        const p1 = data.acf.p1;
                        const p2 = data.acf.p2;
                        const p3 = data.acf.p3;
                        const p4 = data.acf.p4;
                        const p5 = data.acf.p5;
                        const p6 = data.acf.p6;
                        const p7 = data.acf.p7;
                        const image = data.acf.image;
                        const image2 = data.acf.image2;
                        const image3 = data.acf.image3;
                        const image4 = data.acf.image4;
                        const image5 = data.acf.image5;
                        const rawdato = data.acf.rawdato;
                        const dato = data.acf.dato;

                        return <GetNews key={id} heading={heading} p1={p1} p2={p2} p3={p3} p4={p4} p5={p5} p6={p6} p7={p7} rawdato={rawdato} image={image} image2={image2} image3={image3} image4={image4} image5={image5} dato={dato} />;
                    })}  
                </div>
            </div>
            <Footer />
        </>
    )
}

export default NewsPage;