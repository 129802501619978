import React from 'react';
import mfLogo from '../../images/miljøfyrtårn-logo.png';

function Footer() {
  return (
    <footer className='footer'>
        <div className='upper-footer'>
            <div className='footer-img'>
                <img src={mfLogo} alt='Miljøfyrtårn sertifisert'/>
            </div>
            <div className='footer-frakt'>
                <h4>Frakt</h4>
                <p>Frakt kommer i tillegg.</p>
            </div>
            <div className='footer-nyheter'>
                <h4>nyheter</h4>
                <p>Klikk <a href='https://www.facebook.com/banefilter.no/'>her</a> og lik vår facebook side for gjevnnlige nyheter og oppdateringer.</p>
            </div>
            <div className='footer-kontakt-oss'>
                <h4>Kontakt oss</h4>
                <p>Karsten Røttingen Tlf: 975 98 058</p>
                <p>karsten@banefilter.no</p>
                <p>Kristoffer Røttingen Tlf: 468 65 077</p>
                <p>kristoffer@banefilter.no</p>
            </div>
        </div>
        <div className='bottom-footer'>
            <p>© 2020 All rights reserved Banefilter AS</p>
        </div>
    </footer>
  )
}

export default Footer