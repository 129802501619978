import React, { useState } from 'react';

function GetNews({ id, heading, p1, p2, p3, p4, p5, p6, p7, rawdato, image, image2, image3, image4, image5, dato}) {

  const articleStructuredData = {
    "@context": "http://schema.org",
    "@type": "Article",
    headline: `${heading}`,
    name: `${heading}`,
    image: `${image}`,
    articleBody: `${p1}`,
    datePublished: `${rawdato}`,
  }

  const [open, setOpen] = useState(false);
  const [img2, setimg2] = useState(false);
  const [img3, setimg3] = useState(false);
  const [img4, setimg4] = useState(false);
  const [img5, setimg5] = useState(false);
  
  

  return (
    <div className='news-content'>
      <script type="application/ld+json">
        {JSON.stringify(articleStructuredData)}
      </script>
      <div className='news-line'></div>
      <h2>{articleStructuredData.name}</h2>
      <div className='date-line'></div>
      <div className='news-date'>{dato}</div>
      <div className='news-images'>
          <div className='news-main-img'>
              <img id='myImg' src={articleStructuredData.image} alt={articleStructuredData.headline} onClick={() => {setOpen(!open)}} />
              {open && 
                <div id="myModal" className="modal">
                  <span onClick={() => {setOpen(!open)}} className="close">&times;</span>
                  <img className='modal-content' src={articleStructuredData.image} alt={articleStructuredData.headline} />
                </div>
              }
          </div>
          <div className='news-img-grid'>
              <img id='img2' onClick={() => {setimg2(!img2)}} className={image2 === undefined ? "none-display" : "" || image2 === false ? "none-display" : ""} src={image2} alt={heading} />
              {img2 && 
                <div id="myModal" className="modal">
                  <span onClick={() => {setimg2(!img2)}} className="close">&times;</span>
                  <img className='modal-content' src={image2} alt={heading} />
                </div>
              }
              <img id='img3' onClick={() => {setimg3(!img3)}} className={image3 === undefined ? "none-display" : "" || image3 === false ? "none-display" : ""} src={image3} alt={heading} />
              {img3 && 
                <div id="myModal" className="modal">
                  <span onClick={() => {setimg3(!img3)}} className="close">&times;</span>
                  <img className='modal-content' src={image3} alt={heading} />
                </div>
              }
              <img id='img4' onClick={() => {setimg4(!img4)}} className={image4 === undefined ? "none-display" : "" || image4 === false ? "none-display" : ""} src={image4} alt={heading} />
              {img4 && 
                <div id="myModal" className="modal">
                  <span onClick={() => {setimg4(!img4)}} className="close">&times;</span>
                  <img className='modal-content' src={image4} alt={heading} />
                </div>
              }
              <img id='img5' onClick={() => {setimg5(!img5)}} className={image5 === undefined ? "none-display" : "" || image5 === false ? "none-display" : ""} src={image5} alt={heading} />
              {img5 && 
                <div id="myModal" className="modal">
                  <span onClick={() => {setimg5(!img5)}} className="close">&times;</span>
                  <img className='modal-content' src={image5} alt={heading} />
                </div>
              }
          </div>
      </div>
      <div className='news-paragraphs'>
          <p>{articleStructuredData.articleBody}</p>
          <p>{p2}</p>
          <p>{p3}</p>
          <p>{p4}</p>
          <p>{p5}</p>
          <p>{p6}</p>
          <p>{p7}</p>
      </div>
    </div>
    
  );
}

export default GetNews;