import React from 'react';
import { useState, useEffect } from "react";
import { importantNews } from '../../api/Api';
import GetPopularNews from './GetPopularNews';
import Loader from '../../images/banefilter-loader.svg'

function PopularNews() { 
  
    const [news,setNews] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect( () => {
        async function fetchPopularNews() {
            try{
                const resp = await fetch(importantNews);

                if(resp.ok) {
                    const json = await resp.json();
                    setNews(json);
                } else {
                    setError("Something in the API might be wrong!");
                }
            
            } catch (error) {
                setError(error.toString());
            } finally{
                setLoading(false);
            }
        }fetchPopularNews();
    }, []);

    if (loading) {
        return (
            <div id='loader-div'>
                <img id='loader' src={Loader} alt="laoder" />
            </div>
            
        )
    }

    if (error) {
        return <div>ERROR: {error}</div>
    }
  
    return (
        <div className='popuplar-news-section'>
            {news.map( function (data) {
                const id = data.id;
                const image = data.acf.image;
                const bg = data.acf.bg;
                const heading = data.acf.heading;

                return <GetPopularNews key={id} image={image} bg={bg} heading={heading} />;
            })}
        </div>
    )
}

export default PopularNews;