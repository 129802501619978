import emailjs from 'emailjs-com';
import Footer from './components/Footer';
import { useNavigate } from 'react-router-dom';

function ContactUs() {

  const history = useNavigate();
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('send.one.com', 'template_gk4yova', e.target, 'j-vjB7N1w31QVisAg')
      .then((result) => {
        if (result.text === 'OK') {
          history('/takk-kontakt');
        }
      }, (error) => {
        console.log(error.text);
      });
      e.target.reset();

      
  };
  
  return (
    <div className='contact-page'>
      <div className='contact-heading'>
        <h1>Kontakt oss</h1>
      </div>
      <div className='contact-section'>
        <div className='contact-content'>
          <h2>Ta kontakt med oss for spørsmål om produkt, planlegging og andre ting</h2>
          <div className='contact-split'>
            <div className="contact-form">
              <form onSubmit={sendEmail}>
                <div className="form-container ">
                  <div className="fields">
                    <input type="text" className="form-control" placeholder="Navn" name="name"/>
                  </div>
                  <div className="fields">
                    <input type="email" className="form-control" placeholder="Email Addresse" name="email"/>
                  </div>
                  <div className="fields">
                    <input type="text" className="form-control" placeholder="Emne" name="subject"/>
                  </div>
                  <div className="fields">
                    <textarea className="form-control" id="" cols="30" rows="8" placeholder="Melding/Henvendelse" name="message"></textarea>
                  </div>
                  <div>
                    <input type="submit" className="btn" value="Send melding"></input>
                  </div>
                </div>
              </form>
            </div>

            <div className='contact-info'>
              <h4>Karsten Røttingen </h4>
              <p>Tlf: 975 98 058</p>
              <p>karsten@banefilter.no</p>
              <h4 className='pt'>Kristoffer Røttingen</h4>
              <p>Tlf: 468 65 077</p>
              <p>kristoffer@banefilter.no</p>
            </div>
          </div>
        </div>
      </div>

      
      <Footer />
    </div>
  )
}

export default ContactUs