import React from 'react';
import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { url } from '../api/Api';
import Loader from '../images/banefilter-loader.svg'
import Footer from './components/Footer';

function DetailsPage() {

    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [img2, setimg2] = useState(false);
    const [img3, setimg3] = useState(false);
    const [img4, setimg4] = useState(false);
    const [img5, setimg5] = useState(false);
    const [img6, setimg6] = useState(false);
    const [img7, setimg7] = useState(false);
   

    let navigate = useNavigate();
    const { id } = useParams();
    if(!id) {
        navigate("/produkter");
    }

    const detailUrl = url + id;
   
    useEffect( function() {
        async function fetchInfo() {
            try{
                const resp = await fetch(detailUrl);

                if (resp.ok) {
                    const data = await resp.json();
                    setInfo(data);
                    console.log(data);
                }
            
            } catch (error) {
                console.log(error);
            } finally{
                setLoading(false);
            }

        }fetchInfo();
        // eslint-disable-next-line
    }, [detailUrl]);

    if (loading) {
        return (
            <div className='details-page'>
                <div className='details-page-section'>
                    <img id='loader' src={Loader} alt="laoder" />
                </div>
            </div>
            
        )
    }

    if (info.acf.filterimg1 === false) {
        return (
            <>
                <div className='details-page'>
                    <div className='details-page-section'>
                        <div className='details-page-left'>
                            <div className='details-page-grid-img'>
                                <div className='details-page-grid-img-upper'>
                                    <img style={{opacity: info.acf.img2 ? '100%' : '0%' }} src={info.acf.img2} alt={info.acf.navn} onClick={() => {setimg2(!img2)}} />
                                    {img2 && 
                                        <div id="myModal" className="modal">
                                        <span onClick={() => {setimg2(!img2)}} className="close">&times;</span>
                                        <img className='modal-content' src={info.acf.img2} alt={info.acf.navn} />
                                        <p className='img-text'>{info.acf.bilde_tekst2}</p>
                                        </div>
                                    }
                                    <img style={{opacity: info.acf.img3 ? '100%' : '0%' }} src={info.acf.img3} alt={info.acf.navn} onClick={() => {setimg3(!img3)}} />
                                    {img3 && 
                                        <div id="myModal" className="modal">
                                        <span onClick={() => {setimg3(!img3)}} className="close">&times;</span>
                                        <img className='modal-content' src={info.acf.img3} alt={info.acf.navn} />
                                        <p className='img-text'>{info.acf.bilde_tekst3}</p>
                                        </div>
                                    }
                                    <img style={{opacity: info.acf.img4 ? '100%' : '0%' }} src={info.acf.img4} alt={info.acf.navn} onClick={() => {setimg4(!img4)}} />
                                    {img4 && 
                                        <div id="myModal" className="modal">
                                        <span onClick={() => {setimg4(!img4)}} className="close">&times;</span>
                                        <img className='modal-content' src={info.acf.img4} alt={info.acf.navn} />
                                        <p className='img-text'>{info.acf.bilde_tekst4}</p>
                                        </div>
                                    }
                                </div>    
                                <div className='details-page-grid-img-lower'>
                                    <img style={{opacity: info.acf.img5 ? '100%' : '0%' }} src={info.acf.img5} alt={info.acf.navn} onClick={() => {setimg5(!img5)}} />
                                    {img5 && 
                                        <div id="myModal" className="modal">
                                        <span onClick={() => {setimg5(!img5)}} className="close">&times;</span>
                                        <img className='modal-content' src={info.acf.img5} alt={info.acf.navn} />
                                        <p className='img-text'>{info.acf.bilde_tekst5}</p>
                                        </div>
                                    }
                                    <img style={{opacity: info.acf.img6 ? '100%' : '0%' }} src={info.acf.img6} alt={info.acf.navn} onClick={() => {setimg6(!img6)}} />
                                    {img6 && 
                                        <div id="myModal" className="modal">
                                        <span onClick={() => {setimg6(!img6)}} className="close">&times;</span>
                                        <img className='modal-content' src={info.acf.img6} alt={info.acf.navn} />
                                        <p className='img-text'>{info.acf.bilde_tekst6}</p>
                                        </div>
                                    }
                                    <img style={{opacity: info.acf.img7 ? '100%' : '0%' }} src={info.acf.img7} alt={info.acf.navn} onClick={() => {setimg7(!img7)}} />
                                    {img7 && 
                                        <div id="myModal" className="modal">
                                        <span onClick={() => {setimg7(!img7)}} className="close">&times;</span>
                                        <img className='modal-content' src={info.acf.img7} alt={info.acf.navn} />
                                        <p className='img-text'>{info.acf.bilde_tekst7}</p>
                                        </div>
                                    }
                                </div> 
                            </div>
                            <div className='details-page-main-img'>
                                <img onClick={() => {setOpen(!open)}} src={info.acf.img1} alt={info.acf.navn}/>
                                {open && 
                                    <div id="myModal" className="modal">
                                        <span onClick={() => {setOpen(!open)}} className="close">&times;</span>
                                        <img className='modal-content' src={info.acf.img1} alt={info.acf.navn} />
                                        <p className='img-text'>{info.acf.bilde_tekst1}</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='details-right'>
                            <div className='details-right-content'>
                                <h1 data-item-id={info.id}>{info.acf.navn}</h1>
                                <div className='products-mal'>
                                    <p>{info.acf.pm1}</p>
                                    <p>{info.acf.pm2}</p>
                                    <p>{info.acf.pm3}</p>
                                    <p>{info.acf.pm4}</p>
                                </div>
                                <h3>{info.acf.pris}</h3>
                                <p>{info.acf.eksmva}</p>
                                <div className='product-desc'>
                                    <p>{info.acf.pb1}</p>
                                    <p>{info.acf.pb2}</p>
                                </div>
                                <Link to="/bestilling" className='bestilling-knapp'>
                                    Gå til bestilling
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer /> 
            </>
        )
    } else {
        return (
            <>
                <div className='details-page'>
                    <div className='details-page-section'>
                        <div className='details-page-left'>
                            <div className='details-page-grid-img'>
                                
                            </div>
                            <div className='details-page-main-img'>
                                <img onClick={() => {setOpen(!open)}} src={info.acf.img1} alt={info.acf.navn}/>
                                {open && 
                                    <div id="myModal" className="modal">
                                        <span onClick={() => {setOpen(!open)}} className="close">&times;</span>
                                        <img className='modal-content' src={info.acf.img1} alt={info.acf.navn} />
                                        <p className='img-text'>{info.acf.bilde_tekst1}</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='details-right'>
                            <div className='details-right-content'>
                                <h1 data-item-id={info.id}>{info.acf.navn}</h1>
                                <div className='products-mal'>
                                    <p>{info.acf.pm1}</p>
                                    <p>{info.acf.pm2}</p>
                                    <p>{info.acf.pm3}</p>
                                    <p>{info.acf.pm4}</p>
                                </div>
                                <h3>{info.acf.pris}</h3>
                                <p>{info.acf.eksmva}</p>
                                <div className='product-desc'>
                                    <p>{info.acf.pb1}</p>
                                    <p>{info.acf.pb2}</p>
                                </div>
                                <Link to="/bestilling" className='bestilling-knapp'>
                                    Gå til bestilling
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='details-page-section-bottom'>
                        <div className='filtersize-images'>
                            <img src={info.acf.filterimg1} alt="filter size images for 60cm"/>
                            <img src={info.acf.filterimg2} alt="filter size images for 35cm"/>
                            <img src={info.acf.filterimg3} alt="filter size images for 28cm"/>
                        </div>
                    </div>
                </div>
                <Footer /> 
            </>
        )
    }

   


    
}

export default DetailsPage;