import React from 'react'
import { Link } from 'react-router-dom'

function Navlinks() {
  return (
    <div id='nav-web'>
        <ul>
            <li><Link className='nav-links' to="/produkter">Produkter</Link></li>
            <li><Link className='nav-links' to="/kontakt">Kontakt</Link></li>
            <li><Link className='nav-links' to="/om-oss">Om oss</Link></li>
            <li><Link className='nav-links' to="/nyheter">Nyheter</Link></li>
            <li><Link className='nav-links' to="/bestilling">Bestilling</Link></li>
        </ul>
    </div>
  )
}

export default Navlinks