import React from 'react';
import { useState, useEffect } from "react";
import { productsUrl } from '../../api/Api';
import GetProducts from '../components/Getproducts';
import ProductFilter from './ProductFilter';
import Loader1 from '../../images/banefilter-loader1.svg';


function ProductMain() { 

    const [product, setProduct] = useState([]);
    const [catFiltered, setCatFiltered] = useState([]);
    const [activeCategory, setActiveCategory] = useState(27);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        fetchProduct();
    }, []); 


    const fetchProduct = async () => {
        try {
            const resp = await fetch(productsUrl);
            
            if (resp.ok) {
                const json = await resp.json();
                setProduct(json);
                setCatFiltered(json);
                console.log(json);
            } else {
                console.log('There seems to be an errror with the fecth call');
            }

        } catch (error) {
            console.log(error);
        } finally{
            setLoading(false);
        }
    }

    if (loading) {
        return (
            <div className='product-page-main'>
                <ProductFilter product={product} setCatFiltered={setCatFiltered} activeCategory={activeCategory} setActiveCategory={setActiveCategory}  />
                <div className='products-section'>
                    <div id='loader-div'>
                        <img id='loader1' src={Loader1} alt="laoder" />
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className='product-page-main'>
            <ProductFilter product={product} setCatFiltered={setCatFiltered} activeCategory={activeCategory} setActiveCategory={setActiveCategory}  />
            <div className='products-section'>
                <div className='products-grid'>
                    {catFiltered.map( function (data){
                        const id = data.id;
                        const title = data.acf.navn;
                        const pris = data.acf.pris;
                        const img1 = data.acf.img1;
                        const sumpris = data.acf.sumpris;
                        // const img2 = data.acf.img2;
                        // const img3 = data.acf.img3;
                        // const img4 = data.acf.img4;
                        // const img5 = data.acf.img5;
                        const undertext = data.acf.undertekst;
                        const pb1 = data.acf.pb1;
                        // const pb2 = data.acf.pb2;

                        return <GetProducts key={id} sumpris={sumpris} pb1={pb1} undertext={undertext} id={id} img={img1} title={title} price={pris} />;
                    })}
                </div>
            </div>
        </div>
    ) 
}

export default ProductMain;