import React from 'react';
import PotrettBilde from '../images/potrett-karsten-røttingen.jpg';
import GranulatBilde from '../images/om-oss-bilde.jpg';
import Footer from './components/Footer';

function OmOss() {
  return (
    <>
        <div className='about-sec'>
            <div className='about-heading'>
                <h1>Om oss</h1>
            </div>
            <div className='about-main'>
                <div className='about-main-img'>
                    <img src={GranulatBilde} alt="Granulat"/>
                </div>
                <div className='about-main-content'>
                    <h2>Granulatsvinn i naturen</h2>
                    <p>Gummigranulat er små biter med gummi som blir spredd utover banen, som ligger nede i kunstgresset for å være med på å holde det mykt. Med litt sand i bunn og gummigranulat som ligger oppå sanden, blir kunstgresset mykt og godt å spille på.
                        Men det er alltid et men, granulat er gummi, og det får raskt bein å gå på. Granulat setter seg fast under sko, og ender opp «over alt» utenfor banen - på asfalt, i bilen, ut i grøfter, ned i avløpkummer eller hjem i vaskemaskinen. Når banene blir brøytet for snø, får granulat en autostrada ned i avløpskummen ved banen òg. På landsbasis forsvinner ca. 1.500 tonn med gummigranulat fra de norske fotballbanene per år.</p>
                </div>
            </div>  
            <div className='about-beggining'>
                <div className='about-beggining-img'>
                    <img src={PotrettBilde} alt='potrett bilde' />
                </div>
                <div className='about-beggining-content'>
                    <h2>For en mer miljøvennlig bane</h2>
                    <p>Banefilter AS leverer granulatfilter og sluser for oppsamling av gummigranulat fra kunstgressbaner til idrettsanlegg, bedrifter og private. Etter flere år som frivillig i idrettslag hvor arbeidet stort sett var med vedlikehold av fotballbaner og anlegget generelt kom vi bort i problemet med gummigranulat utenfor banen og anlegget. Vi var opptatt av å finne gode og løsninger som kunne brukes av alle. Vi laget granulatfilteret og fikk dette produsert av lokalt firma som vi har god kontakt med. Å begrense granulatsvinn i naturen innebærer også at alle inn- og utganger må sikres. Løsningen for dette fant vi også lokalt. Våre rensestasjoner, spillerstasjoner, publikumssluser, utkjøringssluser, ministasjoner og alt av spesial bestillinger blir laget lokalt. Vårt mål er å kunne levere gunstige og gode kvalitetsprodukter for å hindre at gummigranulat havner i naturen.</p>
                    <p>* kontakt oss så sender vi dere vårt Miljøfyrtårn klimaregnskap, resultater og tiltak.</p>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default OmOss