import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

function GetProducts({ id, undertext, pb1, sumpris, img, title, price}) {

const articleStructuredData = {
  "@context": "http://schema.org",
  "@type": "Product",
  name: `${title}`,
  image: `${img}`,
  description: `${pb1}`,
  brand: {
    "@type": "Banefilter AS",
    logo: "https://sprightly-halva-d57a9a.netlify.app/static/media/banefilter-logo-farge.790266403dec3f0e6595.jpg"
  },
  offers: {
    "@type": "Offer",
    priceCurrency: "NOK",
    price: `${sumpris}`,
  },
}

  return (
    <Link to={`/detail/${id}`} className='product-card'>
        <script type="application/ld+json">
          {JSON.stringify(articleStructuredData)}
        </script>
        <img src={articleStructuredData.image} alt={articleStructuredData.name} />
        <div className='product-card-heading'>
            <h3>{articleStructuredData.name}</h3>
            <p>{undertext}</p>
        </div>
        <div className='product-card-price'>
            <h3>{price}</h3>
            <p>eks.mva</p>
            <button>
              Se mer
            </button>
        </div>
    </Link>
  );
}

GetProducts.propTypes = {
  id: PropTypes.number.isRequired,
  img: PropTypes.string.isRequired,
  undertext: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
}

export default GetProducts;