import React, { useState } from 'react'

function OrderProducts({ id, img1, title, pris }) {

    const [checked, setChecked] = useState("false");
    
    const handleToggle = () => {
        setChecked(!checked);
    };

  return (
    <div className='order-boxes'>
        <img src={img1} alt={title} />
        <div className='order-box-text'>
            <h3>{title}</h3>
            <p>{pris}</p>
            <div className='checkbox-container'>
                <input type="checkbox" className={`checkbox ${id}`} onClick={handleToggle}>
                </input>
                <div className={`fields ${checked ? "show" : ""} value-input how-many${id}`}>
                    <input className="input-label" type="text" name={`value-name${id}`} defaultValue={title}></input>
                    <input type="number" name={`value${id}`} placeholder='0'></input>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OrderProducts;